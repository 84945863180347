@use "./../2-lib/breakpoints" as *;

.article {
  & > * + * {
    margin-block-start: var(--flow-space, 1.5em);
    margin-block-end: 0;
  }

  h1 + *,
  h2 + *,
  h3 + *,
  h4 + *,
  h5 + *,
  h6 + * {
    --flow-space: 0.75em;
  }

  p + ul,
  p + ol {
    --flow-space: 0.75em;
  }

  pre {
    // Overwrite prism styles
    margin-block-start: var(--flow-space, 1.5em);
    margin-block-end: 0;
  }
}

.article {
  p {
    line-height: 1.6;
  }

  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    padding-inline-start: var(--space-4);

    ul,
    ol {
      margin-block-start: 0.3em;
    }

    &[data-link-list] a:not(:hover) {
      text-decoration: none;
    }
  }

  li + li {
    margin-block-start: 0.3em;
  }

  h1 {
    font-size: var(--font-size-fluid-4);
  }

  h2 {
    font-size: var(--font-size-fluid-3);
  }

  h3 {
    font-size: var(--font-size-fluid-2);
  }

  h4 {
    font-size: var(--font-size-fluid-1);
  }

  h5 {
    font-size: var(--font-size-fluid-0);
  }

  h6 {
    font-size: var(--font-size-fluid-x2);
    text-transform: uppercase;
    letter-spacing: 0.01em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.25;
    scroll-margin-block-start: 6rem;
  }

  a {
    color: var(--color-primary-text);
    text-underline-offset: 5px;
    text-decoration-style: dashed;
    text-decoration-thickness: 1px;

    &:hover {
      text-decoration-style: solid;
    }
  }

  table {
    inline-size: 100%;
    border-block-start: 1px solid var(--color-neutral-border);
    border-collapse: collapse;
  }

  table td,
  table th {
    border-bottom: 1px solid var(--color-neutral-border);
    padding-inline: var(--space-1);
  }

  table td {
    padding-block: var(--space-3);
  }

  table th {
    padding-block: var(--space-2);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    letter-spacing: 0.01em;
  }

  blockquote {
    border-inline-start: 3px solid var(--color-neutral-line);
    padding-inline-start: var(--space-4);

    p {
      color: var(--color-neutral-text);
      font-style: italic;
    }
  }

  pre,
  code {
    font-family: var(--font-family-mono);
    line-height: 1.5;
  }

  pre {
    font-size: 14px;
    line-height: 1.375;
    direction: ltr;
    text-align: start;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    tab-size: 2;
    hyphens: none;
    padding: 1em;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.05);
    border-radius: var(--rounded-lg);
    background-color: var(--color-neutral-bg-subtle);
    overflow: auto;
  }

  code {
    font-size: 0.9em;
    padding: 0.2em 0.3em;
    border-radius: var(--rounded-md);
    background-color: var(--color-neutral-bg);
  }

  pre code {
    font-size: inherit;
    padding: 0;
    word-break: break-all;
    background-color: transparent !important;
  }

  .footnotes-sep {
    border-style: solid;
    border-color: var(--color-neutral-line);
  }

  .direct-link {
    outline-offset: 4px;
    text-decoration: none;
    margin-inline-start: 0.1em;
    color: transparent;
    transition: color 300ms ease;
  }

  *:hover > .direct-link,
  .direct-link:focus {
    color: var(--color-neutral-text);
  }
}
