*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  background-color: var(--color-background);
  color: var(--color-neutral-text-contrast);
  font-family: var(--font-family-default);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ul[role] {
  list-style: none;
  padding: 0;
  margin: 0;
}
