@use "./../2-lib/breakpoints.scss" as *;

.page__title {
  margin-block-end: var(--space-6);
  font-size: var(--font-size-fluid-6);
  line-height: 1.3;
}

.page__description {
  margin-block-end: var(--space-6);
  font-size: var(--font-size-fluid-1);
  line-height: 1.3;
}
