.search {
  position: relative;
}

.search__input {
  inline-size: 230px;
  position: relative;
  padding-block: var(--space-2);
  padding-inline: var(--space-5);
  border: none;
  border-radius: var(--rounded-full);
  background-color: var(--color-neutral-bg);
  color: var(--color-neutral-text-contrast);

  .search[data-mode="inline"] & {
    inline-size: 100%;
    display: block;
    padding-inline: var(--space-4);
    border-radius: var(--rounded-md);
  }

  &:hover {
    background-color: var(--color-neutral-bg-hover);
  }

  &::placeholder {
    color: var(--color-neutral-placeholder);
  }
}

.search__kbd {
  display: none;

  input:placeholder-shown:not(:focus) + & {
    display: block;
    position: absolute;
    inset-block-start: 50%;
    inset-inline-end: var(--space-4);
    transform: translateY(-50%);
    color: var(--color-neutral-placeholder);
    font-size: var(--font-size-xs);
    font-family: var(--font-family-mono);
    pointer-events: none;
  }
}

.search__popover {
  .search[data-mode="overlay"] & {
    inline-size: 400px;
    max-block-size: 300px;
    position: absolute;
    z-index: 1;
    inset-block-start: calc(100% + 4px);
    inset-inline-end: 0;
    padding: var(--space-1);
    box-shadow: var(--shadow-lg);
    border-radius: var(--rounded-lg);
    background-color: var(--color-surface);
    overflow: auto;
  }

  .search[data-mode="inline"] & {
    margin-block-start: var(--space-6);
  }
}

.search__info {
  color: var(--color-neutral-text);

  .search[data-mode="overlay"] & {
    padding-inline: var(--space-4);
    padding-block: var(--space-2);
    font-size: var(--font-size-sm);
  }
}

.search__results > li + li {
  margin-block-start: var(--space-1);
}

.search__result {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
  padding-inline: var(--space-4);
  padding-block: var(--space-2);
  border-radius: calc(var(--rounded-lg) - var(--space-1));
  text-decoration: none;

  .search__popover:hover & {
    transition: all 200ms ease;
  }

  &[aria-selected="true"] {
    background-color: var(--color-primary-bg-subtle);
  }
}

.search__result:hover {
  background-color: var(--color-primary-bg-subtle);
}

.search__note-title {
  color: var(--color-primary-text);
  font-weight: var(--font-weight-medium);
}

.search__note-content {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
